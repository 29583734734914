<template>
  <div class="page-notice-list-container">
    <div class="notict-group__header-container" v-if="groupID > 0">
      <p class="container__title">小组</p>
      <router-link
        v-slot="{ navigate }"
        :to="{
          path: `/group/${groupID}/topics`,
        }"
      >
        <p class="group__name" v-if="info" @click="navigate">{{ info.title }}</p>
      </router-link>
    </div>
    <div class="notice-list-main-body">
      <van-list
        class="list__wrapper"
        v-model="requesting"
        :finished="finished"
        finished-text=""
        @load="getList"
      >
        <div v-for="noticeList in mixLists" :key="noticeList.id || noticeList.groupID">
          <template v-if="noticeList.groupID > 0">
            <div
              class="group__notice-item"
              :key="noticeList.groupID"
              :class="[
                noticeList.hasUnRead ? 'unread' : 'hasRead',
                noticeList.isDissolve ? 'isDissolve' : '',
              ]"
              @click="navigateJump(noticeList)"
            >
              <div class="item__inner">
                <div
                  class="item__inner-main"
                  v-long-press="() => onGroupLongPress(noticeList.groupID)"
                >
                  <div class="item__headpic" @click.stop="goGroup(noticeList)">
                    <img :src="noticeList.cover | parseImage" alt="" />
                  </div>
                  <div class="item__main-body">
                    <div class="main__wrapper">
                      <p class="main__name">{{ noticeList.title }}•小组</p>
                      <p class="main__text">
                        {{ noticeList.isDissolve ? "小组已解散" : "组里有动静" }}
                      </p>
                      <p class="main__created-at">
                        {{ noticeList.created_at | parseTime("{y}.{m}.{d}") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="long-press-container" v-show="groupLongPressShow === noticeList.groupID">
                <div
                  class="press-item"
                  @click.stop="onGroupNoticeRemove"
                  :data-groupid="noticeList.groupID"
                  :data-isdissolve="noticeList.isDissolve ? 0 : 1"
                >
                  删除
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="noticeList.type === 51 && !isWeeklyList">
            <div
              class="weekly__notice-item"
              @click="goWeekly()"
              :class="[noticeList.read ? '' : 'unread']"
            >
              <div class="item__inner">
                <div class="item__inner-main" v-long-press="() => onWeeklyLongPress()">
                  <div class="item__headpic" @click.stop="goWeekly()">
                    <img :src="weeklyDefaultPic" alt="" />
                  </div>
                  <div class="item__main-body">
                    <div class="main__wrapper">
                      <p class="main__name">时光•盘点</p>
                      <p class="main__text">
                        {{ noticeList.title }}
                      </p>
                      <p class="main__created-at">
                        {{ noticeList.created_at | parseTime("{y}.{m}.{d}") }}
                      </p>
                    </div>
                  </div>
                  <div class="item__pic">
                    <img :src="'' | parseImage" alt="" />
                  </div>
                </div>
              </div>
              <div class="long-press-container" v-show="groupLongPressShow === -51">
                <div class="press-item" @click.stop="onGroupWeeklyNoticeRemove">删除</div>
              </div>
            </div>
          </template>
          <notice-item
            v-else
            :key="noticeList.id"
            :info="noticeList"
            :groupInfo="info"
            @afterDel="noticeDeleted"
          ></notice-item>
        </div>
      </van-list>
    </div>
    <empty hint="这里显示收到的消息" v-if="isEmpty">
      <svg-icon icon-class="icon-bell" style="margin-bottom: 50px; margin-top: 200px"></svg-icon>
    </empty>
    <slogan v-else text="心怀大海，活在当下"></slogan>
    <div
      class="long-press-mask"
      @click.stop="onCloseLongPress"
      v-show="groupLongPressShow > 0 || groupLongPressShow === -51"
    ></div>
    <!-- 底部按钮 -->
    <div class="group__footer-fixed-nav" v-if="groupID > 0 || isWeeklyList">
      <div class="nav__item" @click="goHome">
        <svg-icon icon-class="icon-home"></svg-icon>
        <p>首页</p>
      </div>
      <div class="nav__item" @click="groupNoticeBack">
        <svg-icon icon-class="icon-back"></svg-icon>
        <p>返回</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as dict from '@/utils/dict';
import { mapMutations, mapState } from 'vuex';
import noticeItem from './_item.vue';
import slogan from '../common/_slogan.vue';
import empty from '../common/_empty.vue';

export default {
  name: 'page-notice-list',
  components: {
    noticeItem,
    slogan,
    empty,
  },
  props: ['groupID'],
  data() {
    return {
      requesting: false,
      finished: false,
      page: 1,
      totalPage: 1,
      lists: [],
      groupMenus: [],
      info: null,
      deletedLists: [],
      mixLists: [],
      isEmpty: false,
      groupLongPressShow: 0,
      catchWeeklyInfo: {
        search: false,
        add: false,
      },
    };
  },
  computed: {
    ...mapState(['localGroupMenus', 'weeklyNotice']),
    groupUnread() {
      return this.mixLists.filter((item) => item.hasUnRead).length;
    },
    listUnread() {
      if (this.groupID === undefined) {
        return this.mixLists.filter(
          (item) => item.basedinfo && item.status === dict.NOTICE_STATUS_UNREAD,
        ).length;
      }
      return 0;
    },
    unreadCount() {
      return this.groupUnread + this.listUnread;
    },
    noticeURI() {
      if (this.groupID === undefined && !this.isWeeklyList) {
        return 'api/user/notice';
      }
      if (this.groupID) {
        return `api/user/notice/group/${this.groupID}`;
      }
      return 'api/user/notice/weekly';
    },
    currentFirstLatest() {
      return this.groupID > 0 && this.mixLists.length > 0 ? this.mixLists['0'].created_at : 0;
    },
    isWeeklyList() {
      return this.$route.params.weekly >= 0;
    },
  },
  watch: {
    unreadCount: {
      handler(val) {
        this.HAS_UNREAD(val);
      },
      immediate: true,
    },
    currentFirstLatest() {
      this.markGroupRead();
    },
    'weeklyNotice.userDelete': {
      handler(val) {
        if (val) {
          const index = this.mixLists.findIndex((item) => +item.type === 51);
          if (index >= 0) {
            this.mixLists.splice(index, 1);
          }
        }
      },
    },
  },
  destroyed() {},
  mounted() {
    if (this.groupID !== undefined) {
      // this.getGroupMenu();
      this.getGroupInfo();
    }
    // 首次获取本地周消息
    this.getWeeklyNotice();
  },
  methods: {
    ...mapMutations([
      'UPDATE_GROUP_MENU',
      'REMOVE_GROUP_MENU',
      'HAS_UNREAD',
      'getWeeklyNotice',
      'setWN',
    ]),
    navigateJump(menu) {
      const that = this;
      const { isDissolve, groupID } = menu;
      if (!isDissolve) {
        that.$router.push({
          path: `/notice/${groupID}/group`,
        });
      } else {
        that.$notify({
          message: '小组已解散',
        });
      }
    },
    markGroupRead() {
      const that = this;
      if (that.mixLists.length > 0) {
        const url = `api/user/notice/mark-read/${that.groupID}?latest=${that.mixLists['0'].created_at}`;
        that.$request.get(url);
      }
    },
    getList() {
      const that = this;

      const p = Number(that.page) === 1 && that.groupID === undefined && !that.isWeeklyList
        ? [that.reqNotices(), that.requestGroupMenu()]
        : [that.reqNotices()];

      Promise.all(p)
        .then((res) => {
          const [resNotice, resGroupMenu] = res;
          let groupMenus = [];
          if (resGroupMenu) {
            const { data: menus } = resGroupMenu;
            that.UPDATE_GROUP_MENU(menus);
            groupMenus = that.localGroupMenus.map((item) => {
              const temp = item;
              temp.created_at = Number(item.currentLastestAt);
              return temp;
            });
          }
          const { list: lists, _meta: meta } = resNotice.data;
          that.totalPage = meta.pageCount;
          that.deletedLists.push(
            ...lists.filter(
              (item) => Number(item.status) === dict.NOTICE_STATUS_UNREAD
                && (item.basedinfo === null || Number(item?.basedinfo.status) === 1)
                && item.basedinfo
                && item.basedinfo.author === null,
            ),
          );
          if (that.page >= that.totalPage) {
            that.finished = true;
          }
          that.page += 1;

          let finalListData = lists;

          // 整合周消息
          if (!this.isWeeklyList) {
            finalListData = lists.filter((item) => {
              if (+item.type === 51 && !that.catchWeeklyInfo.search) {
                const localInfo = that.weeklyNotice;
                if (item.created_at > localInfo.latest.createdAt) {
                  that.setWN({
                    userDelete: false,
                    userRead: false,
                    latest: {
                      createdAt: item.created_at,
                      title: item.extend.title,
                    },
                  });
                }
                that.catchWeeklyInfo.search = true;
              }
              return +item.type !== 51;
            });

            if (
              !that.weeklyNotice.userDelete
              && that.catchWeeklyInfo.search
              && !that.catchWeeklyInfo.add
            ) {
              finalListData.push({
                created_at: that.weeklyNotice.latest.createdAt,
                title: that.weeklyNotice.latest.title,
                read: that.weeklyNotice.userRead,
                type: 51,
              });
              that.catchWeeklyInfo.add = true;
            }
          }

          that.mixLists = groupMenus.length > 0 && that.page === 2
            ? [...groupMenus, ...finalListData].sort((a, b) => b.created_at - a.created_at)
            : [...that.mixLists, ...finalListData];
          that.isEmpty = that.finished && that.mixLists.length === 0;
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    reqNotices() {
      return this.$request.get(this.noticeURI, {
        page: this.page,
      });
    },
    requestGroupMenu() {
      return this.$request.get('api/user/notice/group-menu');
    },
    noticeDeleted(id) {
      const temp = this.mixLists;
      this.mixLists = temp.filter((item) => Number(item.id) !== Number(id));
    },
    getGroupMenu() {
      const that = this;
      that.$request.get('api/user/notice/group-menu').then((res) => {
        const { data: menus } = res;
        that.UPDATE_GROUP_MENU(menus);
        // console.log(that.localGroupMenus);
        that.groupMenus = that.localGroupMenus;
        // that.groupMenus = menus;
      });
    },
    getGroupInfo() {
      const that = this;
      that.$request.get(`api/group/${that.groupID}`).then((res) => {
        const { data } = res;
        that.info = Object.assign(data.info, {
          isMember: data.isMember,
        });
      });
    },
    groupNoticeBack() {
      this.$router.replace({
        path: '/',
      });
    },
    onSwipeCellBeforeClose({ position, instance }) {
      const that = this;
      const { groupid: groupID, isdissolve: isDissolve } = instance.$el.dataset;
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
        default:
          instance.close();
          break;
        case 'right':
          that.$dialog
            .confirm({
              title: '提示信息',
              message: '确定清空当前小组消息？',
              beforeClose: (action, done) => {
                if (action === 'confirm') {
                  that.$request
                    .get(`api/group/remove-notice/${Number(groupID)}?action=mr`)
                    .then(() => {
                      that.mixLists = that.mixLists.filter(
                        (group) => Number(group.groupID) !== Number(groupID),
                      );
                      if (Number(isDissolve) === 0) {
                        that.REMOVE_GROUP_MENU(groupID);
                      }
                    })
                    .finally(() => {
                      done();
                    });
                } else {
                  done();
                }
              },
            })
            .then(() => {
              instance.close();
            });
          break;
      }
    },
    goGroup({ isDissolve, groupID }) {
      if (isDissolve) {
        this.$notify({
          message: '小组已解散',
        });
      } else {
        this.$router.push({
          path: `/group/${groupID}`,
        });
      }
    },
    onGroupLongPress(gid) {
      this.groupLongPressShow = gid;
    },
    onCloseLongPress() {
      this.groupLongPressShow = 0;
    },
    onGroupNoticeRemove(e) {
      const that = this;
      const { groupid: groupID, isdissolve: isDissolve } = e.currentTarget.dataset;
      that.$dialog
        .confirm({
          title: '提示信息',
          message: '确定清空当前小组消息？',
          beforeClose: (action, done) => {
            if (action === 'confirm') {
              that.$request
                .get(`api/group/remove-notice/${Number(groupID)}?action=mr`)
                .then(() => {
                  that.mixLists = that.mixLists.filter(
                    (group) => Number(group.groupID) !== Number(groupID),
                  );
                  if (Number(isDissolve) === 0) {
                    that.REMOVE_GROUP_MENU(groupID);
                  }
                })
                .finally(() => {
                  done();
                });
            } else {
              done();
            }
          },
        })
        .then(() => {
          // instance.close();
          // that.onCloseLongPress();
        })
        .finally(() => {
          that.onCloseLongPress();
        });
    },
    onGroupWeeklyNoticeRemove() {
      this.partialSetWN({
        userDelete: true,
      });
      this.onCloseLongPress();
    },
    onWeeklyLongPress() {
      this.groupLongPressShow = -51;
    },
    onCloseWeeklyLongPress() {},
    goWeekly() {
      this.partialSetWN({
        userRead: true,
      });
      this.$router.push({
        path: '/notice/0/weekly',
      });
    },
    partialSetWN(setting) {
      const current = this.weeklyNotice;
      const data = { ...current, ...setting };
      this.setWN(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.long-press-mask {
  @include long-press-mask;
  z-index: 2000;
}
</style>
