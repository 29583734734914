<template>
  <div
    class="notice-list-item"
    v-if="info"
    :class="{
      unread: info.basedinfo && Number(info.status) === unreadStatus,
      deleted: noticeClassDeleted(info),
    }"
  >
    <div class="item__inner" @click.stop="onClick(info)">
      <div class="item__inner-main" v-long-press="onLongPress">
        <div class="item__headpic" @click.stop="pageJump(info)">
          <img :src="sender(info, 'headpic')" alt="" />
        </div>
        <div class="item__main-body">
          <div class="main__wrapper">
            <p class="main__name">
              {{ sender(info, "nickname") | comment(sender(info, "uid")) }}
            </p>
            <p class="main__text" v-html="sender(info, 'content')"></p>
            <p class="main__created-at">{{ info.created_at | parseTime("{y}.{m}.{d}") }}</p>
          </div>
        </div>
        <div class="item__pic">
          <img :src="sender(info, 'sidePic') | parseImage" alt="" />
        </div>
      </div>
      <!-- <van-swipe-cell stop-propagation :before-close="onSwipeCellBeforeClose" :data-id="info.id">
        <template #right>
          <div class="swipe-cell-button red __in-notice-item">
            <van-button class="p">删除</van-button>
          </div>
        </template>
      </van-swipe-cell> -->
    </div>

    <modal
      :visible="modalShow"
      :id="groupInviteId"
      :loading="requesting"
      @refuse="onGroupRefuse"
      @accept="onGroupAccept"
    >
    </modal>

    <div class="long-press-container" v-show="longPressShow">
      <div class="press-item" @click.stop="onRemoveNotice" :data-id="info.id">删除</div>
    </div>
    <div class="long-press-mask" @click.stop="onLongPressClose" v-show="longPressShow"></div>
  </div>
</template>

<script>
import * as dict from '@/utils/dict';
import { mapMutations, mapState } from 'vuex';
import { transformRegReply } from '@/filters';
import modal from '../common/_modal.vue';

// const logoutDefault = {
//   nickname: '已注销',
//   headpic: 'http://timeline-file.crosscrew.cc/static/notice-default.png',
//   headimgurl: 'http://timeline-file.crosscrew.cc/static/notice-default.png',
//   uid: 0,
// };

export default {
  name: 'c__notice-item',
  props: {
    info: {
      type: Object,
      default: null,
    },
    groupInfo: {
      type: Object,
      default: null,
    },
  },
  components: {
    modal,
  },
  data() {
    return {
      unreadStatus: dict.NOTICE_STATUS_UNREAD,
      modalShow: false,
      groupInviteId: 0,
      requesting: false,
      currenGroupInviteInfo: null,
      longPressShow: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['clearRemovedGroup', 'HAS_UNREAD']),
    sender(notice, renderType) {
      const type = Number(notice.type);
      const headDefault = type === 51 ? this.weeklyDefaultPic : this.defaultBg;
      let render = {
        nickname: notice.basedinfo === null ? '神秘人物' : '',
        uid: '',
        content: notice.basedinfo === null ? '神秘内容已撤回' : '',
        headpic: notice.basedinfo === null ? headDefault : '',
        sidePic: notice.basedinfo === null ? this.defaultBg : '',
      };
      if (notice.basedinfo === null && !dict.isSysNotice(type)) {
        return render[renderType];
      }
      if (type === dict.NOTICE_TYPE_SYS_WEEKLY_REPORT) {
        render = {
          nickname: 'CROSSiDEA',
          uid: 0,
          content: notice.extend.title,
          headpic: headDefault,
          sidePic: this.defaultBg,
        };
      } else {
        switch (type) {
          case dict.NOTICE_TYPE_FEEDBACK_REPLY:
            render = {
              nickname:
                Number(this.user?.is_admin) === 1
                  ? this.isDestroyed(notice.basedinfo.author, 'nickname')
                  : '时光',
              uid: this.isDestroyed(notice.basedinfo.author, 'uid'),
              content: `${
                Number(notice.basedinfo.status) === 1 ? '神秘内容已撤回' : notice.basedinfo.content
              }`,
              headpic:
                Number(this.user?.is_admin) === 1
                  ? this.isDestroyed(notice.basedinfo.author, 'headimgurl')
                  : this.defaultBg,
              sidePic:
                // eslint-disable-next-line no-nested-ternary
                notice.basedinfo.imgs && notice.basedinfo.imgs.length > 0
                  ? notice.basedinfo.imgs['0']
                  : notice.basedinfo._post_content && notice.basedinfo._post_content.imgs.length > 0
                    ? notice.basedinfo._post_content.imgs['0']
                    : this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_FEEDBACK:
            render = {
              nickname: this.isDestroyed(notice.basedinfo.author, 'nickname'),
              uid: this.isDestroyed(notice.basedinfo.author, 'uid'),
              content: `${
                Number(notice.basedinfo.status) === 1 ? '神秘内容已撤回' : notice.basedinfo.content
              }`,
              headpic: this.isDestroyed(notice.basedinfo.author, 'headimgurl'),
              sidePic:
                // eslint-disable-next-line no-nested-ternary
                notice.basedinfo.imgs && notice.basedinfo.imgs.length > 0
                  ? notice.basedinfo.imgs['0']
                  : notice.basedinfo._post_content && notice.basedinfo._post_content.imgs.length > 0
                    ? notice.basedinfo._post_content.imgs['0']
                    : this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_REPLY:
            render = {
              nickname: this.isDestroyed(notice.basedinfo.author, 'nickname'),
              uid: this.isDestroyed(notice.basedinfo.author, 'uid'),
              content: `${
                Number(notice.basedinfo.status) === 1 ? '神秘内容已撤回' : notice.basedinfo.content
              }`,
              headpic: this.isDestroyed(notice.basedinfo.author, 'headimgurl'),
              sidePic:
                // eslint-disable-next-line no-nested-ternary
                notice.basedinfo.imgs.length > 0
                  ? notice.basedinfo.imgs['0']
                  : notice.basedinfo._post_content.imgs.length > 0
                    ? notice.basedinfo._post_content.imgs['0']
                    : this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_INVITE: {
            // const handleStatus = notice.basedinfo.handler_status;
            let content;
            if (notice.basedinfo.group) {
              const groupTitle = notice.basedinfo.group.title;
              content = `邀请你加入小组${groupTitle}`;
            } else {
              content = '小组不存在或已解散';
            }

            render = {
              nickname: notice.basedinfo.inviter.nickname,
              uid: notice.basedinfo.invite_uid,
              content,
              headpic: notice.basedinfo.inviter.headimgurl,
              sidePic: notice.basedinfo.group ? notice.basedinfo.group.cover : this.defaultBg,
            };
            break;
          }
          case dict.NOTICE_TYPE_TIMELINE_POST:
            render = {
              nickname: this.isDestroyed(notice.basedinfo.author, 'nickname'),
              uid: this.isDestroyed(notice.basedinfo.author, 'uid'),
              content: `${notice.basedinfo.info.content}`,
              headpic: this.isDestroyed(notice.basedinfo.author, 'headimgurl'),
              sidePic:
                notice.basedinfo.info.imgs.length > 0
                  ? notice.basedinfo.info.imgs['0']
                  : this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_TIMELINE_REPLY:
            render = {
              nickname: notice.basedinfo.author?.nickname,
              uid: notice.basedinfo.author?.uid,
              content: `${
                Number(notice.basedinfo.status) === 1 ? '神秘内容已撤回' : notice.basedinfo.content
              }`,
              headpic: notice.basedinfo.author?.headimgurl,
              sidePic:
                // eslint-disable-next-line no-nested-ternary
                notice.basedinfo.imgs.length > 0
                  ? notice.basedinfo.imgs['0']
                  : notice.basedinfo._post_content && notice.basedinfo._post_content.imgs.length > 0
                    ? notice.basedinfo._post_content.imgs['0']
                    : this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_TOPIC_REPLY:
            render = {
              nickname: this.isDestroyed(notice.basedinfo.author, 'nickname'),
              uid: this.isDestroyed(notice.basedinfo.author, 'uid'),
              content: `${
                Number(notice.basedinfo.status) === 1 ? '神秘内容已撤回' : notice.basedinfo.content
              }`,
              headpic: this.isDestroyed(notice.basedinfo.author, 'headimgurl'),
              sidePic:
                // eslint-disable-next-line no-nested-ternary
                notice.basedinfo.imgs.length > 0
                  ? notice.basedinfo.imgs['0']
                  : notice.basedinfo._post_content && notice.basedinfo._post_content.imgs.length > 0
                    ? notice.basedinfo._post_content.imgs['0']
                    : this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_FRIEND_INVITE:
            render = {
              nickname: this.isDestroyed(notice.basedinfo.user, 'nickname'),
              uid: this.isDestroyed(notice.basedinfo.user, 'uid'),
              content: '申请成为您的好友',
              headpic: this.isDestroyed(notice.basedinfo.user, 'headimgurl'),
              sidePic: this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_FRIEND_INVITE_PASSED:
            render = {
              nickname: notice.basedinfo.user?.nickname,
              uid: notice.basedinfo.user?.uid,
              content: '通过了你的好友请求',
              headpic: notice.basedinfo.user?.headimgurl,
              sidePic: this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_APPLY_REFUSE:
            render = {
              nickname: notice.basedinfo.group.title,
              uid: '',
              content: '拒绝了你的申请',
              headpic: `${process.env.VUE_APP_IMAGE_URL}${notice.basedinfo.group.cover}`,
              sidePic: this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_APPLY_ACCEPT:
            render = {
              nickname: notice.basedinfo.group?.title,
              uid: '',
              content: '通过了你的申请',
              headpic: `${process.env.VUE_APP_IMAGE_URL}${notice.basedinfo.group?.cover}`,
              sidePic: this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_SET_ADMIN:
          case dict.NOTICE_TYPE_GROUP_REMOVE_ADMIN:
            {
              const actionText = type === dict.NOTICE_TYPE_GROUP_SET_ADMIN ? '设置您为小组' : '撤销了您的小组';
              render = {
                nickname: this.isDestroyed(notice.basedinfo.author, 'nickname'),
                uid: this.isDestroyed(notice.basedinfo.author, 'uid'),
                content: `${actionText}管理员，如有疑问请联系Ta`,
                headpic: this.isDestroyed(notice.basedinfo.author, 'headimgurl'),
                sidePic: notice.basedinfo.cover || this.defaultBg,
              };
            }
            break;
          case dict.NOTICE_TYPE_GROUP_INFO_UPDATE:
            render = {
              nickname: notice.extend.nickname,
              uid: notice.extend.uid,
              content: '修改了小组信息',
              headpic: notice.extend.headimgurl,
              sidePic: notice.basedinfo.cover || this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_HIDE_POST:
            render = {
              nickname: notice.extend.nickname,
              uid: notice.extend.uid,
              content: '隐藏了小组内容',
              headpic: notice.extend.headimgurl,
              sidePic: notice.basedinfo.pic || this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_DISSOLVE:
            render = {
              nickname: notice.extend.nickname,
              uid: notice.extend.uid,
              content: `解散了${notice.extend.groupname}小组`,
              headpic: notice.extend.headimgurl,
              sidePic: this.defaultBg,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_REMOVE_MEMBER:
            render = {
              nickname: notice.extend.nickname,
              uid: notice.extend.uid,
              content: `${notice.extend.nickname}将您移出了小组，如有疑问请联系Ta`,
              headpic: notice.extend.headimgurl,
              sidePic: notice.extend.groupcover,
            };
            break;
          case dict.NOTICE_TYPE_GROUP_USER_APPLY:
          case dict.NOTICE_TYPE_GROUP_USER_REMOVE:
            render = {
              nickname: notice.extend.nickname,
              uid: notice.extend.uid,
              content: `${notice.basedinfo}${notice.extend.groupname}`,
              headpic: notice.extend.headimgurl,
              sidePic: this.defaultBg,
            };
            break;
          default:
            break;
        }
      }

      if (renderType === 'content') {
        let content = '';
        if (render[renderType]) {
          const _str = transformRegReply(render[renderType]);
          content = _str.replace(/<\/?.+?>/g, '');
        }
        return content;
      }
      return render[renderType];
    },
    onClick(info) {
      // 标记已读
      this.readNotice(info);
      const { type, basedinfo } = info;
      if (basedinfo !== null) {
        let url;
        let isOriginalDeleted = false;
        switch (type) {
          case dict.NOTICE_TYPE_FEEDBACK_REPLY:
          case dict.NOTICE_TYPE_FEEDBACK:
            url = '/instructions/feedback';
            break;
          case dict.NOTICE_TYPE_GROUP_REPLY:
            isOriginalDeleted = info.basedinfo._post_content._original_post_status === 1;
            url = `/group/${info.basedinfo._group_id}/post/${info.basedinfo.post_id}`;
            break;
          case dict.NOTICE_TYPE_GROUP_INVITE:
            // 处理小组邀请
            this.groupInviteHandle(info);
            break;
          case dict.NOTICE_TYPE_TIMELINE_POST:
            isOriginalDeleted = info.basedinfo.info._original_post_status === 1;
            url = `${
              info.extend && info.extend.groupID
                ? `/group/${info.extend.groupID}/timeline/${info.basedinfo.id}`
                : `/timeline/${info.basedinfo.id}`
            }`;
            break;
          case dict.NOTICE_TYPE_TIMELINE_REPLY:
            isOriginalDeleted = info.basedinfo._post_content._original_post_status === 1;
            url = info.extend && info.extend.groupID
              ? `/group/${info.extend.groupID}/timeline/${info.basedinfo.post_id}`
              : `/timeline/${info.basedinfo.post_id}`;
            break;
          case dict.NOTICE_TYPE_TOPIC_REPLY:
            isOriginalDeleted = info.basedinfo._post_content._original_post_status === 1;
            url = info.extend && info.extend.groupID
              ? `/group/${info.extend.groupID}/topic/post/${info.basedinfo.post_id}`
              : `/topic/post/${info.basedinfo.post_id}`;
            break;
          case dict.NOTICE_TYPE_FRIEND_INVITE:
          case dict.NOTICE_TYPE_FRIEND_INVITE_PASSED:
            url = `/friend/${info.basedinfo.user.uid}`;
            break;
          case dict.NOTICE_TYPE_GROUP_APPLY_ACCEPT:
          case dict.NOTICE_TYPE_GROUP_APPLY_REFUSE:
            url = `/group/${info.basedinfo.group_id}`;
            break;
          case dict.NOTICE_TYPE_GROUP_SET_ADMIN:
            url = `/group/${info.relation_id}`;
            break;
          case dict.NOTICE_TYPE_GROUP_INFO_UPDATE:
            url = `/group/${info.relation_id}`;
            break;
          case dict.NOTICE_TYPE_GROUP_HIDE_POST:
            url = `/group/${info.extend.groupID}`;
            break;
          case dict.NOTICE_TYPE_GROUP_USER_APPLY:
            url = `/group/${info.extend.groupID}/apply`;
            break;
          default:
            break;
        }
        if (
          type === dict.NOTICE_TYPE_GROUP_REMOVE_MEMBER
          || type === dict.NOTICE_TYPE_GROUP_REMOVE_ADMIN
        ) {
          const { nickname } = info.extend;
          const message = type === dict.NOTICE_TYPE_GROUP_REMOVE_MEMBER
            ? `${nickname} 将您移除了小组，如有疑问请联系Ta`
            : `${nickname} 撤销了您的小组管理员，如有疑问请联系Ta`;
          this.$dialog.alert({
            title: '提示信息',
            message,
          });
          return;
        }
        if (this.groupInfo && !this.groupInfo.isMember && type !== dict.NOTICE_TYPE_GROUP_INVITE) {
          this.$dialog.alert({
            title: '提示信息',
            message: '您已不在此小组，请联系确认',
          });
          return;
        }
        if (isOriginalDeleted) {
          this.$dialog.alert({
            title: '提示信息',
            message: '该内容已消失',
          });
          return;
        }
        if (url !== undefined) {
          this.$router.push({
            path: url,
          });
        }
      } else if (dict.isSysNotice(type)) {
        this.$router.push({
          path: `/notice/weekly-report?id=${info.id}`,
        });
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '该内容已消失',
        });
      }
    },
    readNotice(info) {
      const that = this;
      const { status, id } = info;
      const groupID = info?.extend?.groupID || 0;
      if (Number(status) === 1) {
        that.$request.get(`api/user/notice/read/${id}`).then((res) => {
          that.$set(that.info, 'status', res.status);
        });
        if (groupID === 0) {
          that.HAS_UNREAD(-1);
        }
      }
    },
    groupInviteHandle(info) {
      const that = this;
      if (Number(info.status) !== 2 || info.basedinfo.handler_status === 0) {
        that.groupInviteId = info.relation_id;
        that.currenGroupInviteInfo = info;
        that.$dialog
          .confirm({
            className: 'jion-group',
            title: '加入小组？',
            message: '',
            confirmButtonText: '立即加入',
            confirmButtonColor: '#000',
            cancelButtonText: '取消',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                that.onGroupAccept(done);
              } else {
                done();
              }
            },
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (info.basedinfo.handler_status === 1) {
        that.$router.push({
          path: `/group/${info.basedinfo.group_id}`,
        });
      }
    },
    onGroupRefuse() {
      const that = this;
      const url = `api/invite/refuse/${this.groupInviteId}?type=${dict.NOTICE_TYPE_GROUP_INVITE}`;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(url)
          .then(() => {
            // 拒绝邀请
            that.$notify({
              type: 'success',
              message: '操作成功',
            });
          })
          .finally(() => {
            that.requesting = false;
            that.modalShow = false;
          });
      }
    },
    onGroupAccept(callback) {
      const that = this;
      const groupID = that.currenGroupInviteInfo.basedinfo.group_id;
      const path = `/group/${groupID}`;
      const url = `api/invite/accept/${this.groupInviteId}?type=${dict.NOTICE_TYPE_GROUP_INVITE}`;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(url)
          .then(() => {
            // 成功接受邀请
            that.$router.push({
              path,
            });
            // 去掉存过的信息
            that.clearRemovedGroup(groupID);
          })
          .finally(() => {
            that.requesting = false;
            that.modalShow = false;
            callback();
          });
      }
    },
    onSwipeCellBeforeClose({ position, instance }) {
      const that = this;
      const noticeId = instance.$el.dataset.id;
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
        default:
          instance.close();
          break;
        case 'right':
          that.$dialog
            .confirm({
              title: '提示消息',
              message: '确定删除吗？',
              beforeClose: (action, done) => {
                if (action === 'confirm') {
                  that.deletNotice(noticeId).then((res) => {
                    if (res.data === 'SUCCESS') {
                      that.$emit('afterDel', noticeId);
                    }
                    done();
                  });
                } else {
                  done();
                }
              },
            })
            .then(() => {
              instance.close();
            });
          break;
      }
    },
    deletNotice(noticeId) {
      const url = `api/user/notice/${noticeId}`;
      return this.$request.del(url);
    },
    noticeClassDeleted(info) {
      return (
        (info.basedinfo === null && !dict.isSysNotice(info.type))
        || (Number(info.type) === dict.NOTICE_TYPE_GROUP_INVITE && info.basedinfo.handler_status === 1)
      );
    },
    pageJump(info) {
      const uid = this.sender(info, 'uid');
      if (uid) {
        this.userJump(uid);
      } else if (dict.isSysNotice(info.type)) {
        this.$router.push({
          path: `/notice/weekly-report?id=${info.id}`,
        });
      } else if (info.extend) {
        const { extend } = info;
        this.$router.push({
          path: `/group/${extend.groupID}`,
        });
      }
    },
    isDestroyed(info, key) {
      return info ? info[key] : dict.logoutDefault[key];
    },

    onLongPress() {
      this.longPressShow = true;
    },
    onLongPressClose() {
      this.longPressShow = false;
    },
    onRemoveNotice(e) {
      const that = this;
      const noticeId = e.currentTarget.dataset.id;
      that.$dialog
        .confirm({
          title: '提示消息',
          message: '确定删除吗？',
          beforeClose: (action, done) => {
            if (action === 'confirm') {
              that.deletNotice(noticeId).then((res) => {
                if (res.data === 'SUCCESS') {
                  that.$emit('afterDel', noticeId);
                }
                done();
              });
            } else {
              done();
            }
          },
        })
        .then(() => {
          // that.onLongPressClose();
          console.log('before close 处理完了');
        })
        .catch(() => {
          console.log('取消了');
        })
        .finally(() => {
          that.onLongPressClose();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.long-press-mask {
  @include long-press-mask;
  z-index: 2000;
}
</style>
