<template>
  <van-popup v-model="show" :close-on-click-overlay="false">
    <div class="popup-container">
      <div class="popup__main-body">
        <p class="main-body__title" v-if="modalTitle">{{ modalTitle }}</p>
        <p class="main-body__content" v-if="modalContent">**** {{ modalContent }} ****</p>
      </div>
      <div class="popop__bottom-container">
        <div class="bottom-bt -cancel" @click="onRefuse">拒绝</div>
        <van-button class="bottom-bt -submit" @click="onAccept" :loading="innerLoading"
          >接受</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'c__modal',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    modalTitle: {
      type: String,
      default: '加入小组',
    },
    modalContent: {
      type: String,
      default: '邀请你加入小组',
    },
    submitText: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    submitCallback: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // show: vm.visible,
    };
  },
  computed: {
    innerLoading() {
      return this.loading;
    },
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    onRefuse() {
      this.$emit('refuse');
    },
    onAccept() {
      this.$emit('accept');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixin";
.popup-container {
  width: 560px;
  min-height: 288px;
  background-color: #fff;
  position: relative;
  @include flex-box;
  flex-direction: column;
  .popup__main-body {
    width: 100%;
    flex: 1;
    @include flex-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      line-height: 1;
      text-align: center;
    }
    .main-body__title {
      font-weight: bold;
      font-size: 32px;
      color: #030303;
    }
    .main-body__content {
      font-size: 28px;
      color: #848484;
      margin-top: 25px;
    }
  }
  .popop__bottom-container {
    width: 100%;
    flex: 0 0 90px;
    border-top: 1px solid #bbb;
    .bottom-bt {
      display: inline-block;
      zoom: 1;
      width: 50%;
      height: 100%;
      font-size: 32px;
      color: #bbb;
      line-height: 90px;
      text-align: center;
      padding: 0 !important;
      &.-cancel {
      }
      &.-submit {
        font-weight: bold;
        color: #000;
        border: 0 !important;
      }
      vertical-align: top !important;
      &:nth-child(1) {
        border-right: 1px solid #bbb;
      }
    }
  }
}
</style>
